import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [13,4];

export const dictionary = {
		"/(landing)": [57],
		"/(rgpd)/(uppercase)/CGU": [61,[12,13]],
		"/(rgpd)/(uppercase)/CGV": [62,[12,13]],
		"/(user-creation)/account-validation/[token]": [65,[14]],
		"/(d)/admin": [36,[4,8]],
		"/(d)/admin/interventions": [37,[4,8]],
		"/(d)/admin/pricing": [38,[4,8]],
		"/(d)/admin/stats": [39,[4,8]],
		"/(d)/admin/teams": [40,[4,8]],
		"/(d)/admin/users": [41,[4,8]],
		"/(d)/(assistants)/assistants": [23,[4,5]],
		"/(d)/(assistants)/assistants/create": [25,[4,5]],
		"/(d)/(assistants)/assistants/[refId]": [24,[4,5]],
		"/(d)/(assistants)/assistant": [22,[4,5]],
		"/a/[refId]": [69,[15]],
		"/a/[refId]/accept": [70,[15]],
		"/a/[refId]/annotation": [71,[15]],
		"/a/[refId]/assistant": [72,[15]],
		"/a/[refId]/infos": [73,[15]],
		"/a/[refId]/report": [74,[15]],
		"/a/[refId]/settings": [75,[15]],
		"/a/[refId]/widget": [76,[15]],
		"/(rgpd)/cgu": [63,[12]],
		"/(rgpd)/cgv": [64,[12]],
		"/(d)/contacts": [42,[4]],
		"/(d)/(settings)/credits": [~26,[4,6]],
		"/(user-creation)/email-sent": [66,[14]],
		"/(user-creation)/engagement/[key]": [67,[14]],
		"/(landing)/en": [58],
		"/exposition": [77],
		"/(fixee-tests)/fixee-tests-2": [56,[11]],
		"/(fixee-tests)/fixee-tests": [55,[10]],
		"/(landing)/fr": [59],
		"/(d)/home": [43,[4]],
		"/(d)/interventions": [44,[4]],
		"/(d)/interventions/[refId]": [45,[9]],
		"/(d)/interventions/[refId]/accept": [46,[9]],
		"/(d)/interventions/[refId]/annotation": [47,[9]],
		"/(d)/interventions/[refId]/assistant": [48,[9]],
		"/(d)/interventions/[refId]/infos": [49,[9]],
		"/(d)/interventions/[refId]/report": [50,[9]],
		"/(d)/interventions/[refId]/settings": [51,[9]],
		"/(d)/interventions/[refId]/widget": [52,[9]],
		"/(d)/(settings)/invoices": [27,[4,6]],
		"/i/[refId]": [78,[16]],
		"/i/[refId]/accept": [79,[16]],
		"/i/[refId]/annotation": [80,[16]],
		"/i/[refId]/assistant": [81,[16]],
		"/i/[refId]/infos": [82,[16]],
		"/i/[refId]/report": [83,[16]],
		"/i/[refId]/settings": [84,[16]],
		"/i/[refId]/widget": [85,[16]],
		"/(auth)/login": [17,[2]],
		"/(user-creation)/portal-init": [68,[14]],
		"/(landing)/pricing_old": [60],
		"/(d)/(settings)/pricing": [28,[4,6]],
		"/(d)/(settings)/pricing/credits": [29,[4,6]],
		"/(d)/(settings)/procedures": [30,[4,6]],
		"/(d)/(settings)/procedures/(subgroup)/create": [32,[4,6,7]],
		"/(d)/(settings)/procedures/(subgroup)/[procedureId]": [31,[4,6,7]],
		"/(d)/(settings)/profile": [33,[4,6]],
		"/(auth)/register": [18,[2]],
		"/(auth)/request-reset-password": [19,[2]],
		"/(auth)/request-reset-password/email-sent": [20,[3]],
		"/(auth)/reset-password/[token]": [21,[2]],
		"/(d)/statistics": [53,[4]],
		"/(d)/team-reports/[shortId]": [54,[4]],
		"/(d)/(settings)/teams/[id]": [34,[4,6]],
		"/(d)/(settings)/whatsapp": [35,[4,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';